import NoRowsOverlay from '@/components/atoms/Icon/NoRowsOverlay'
import { EnergySavingLevel, EnergySavingReport } from '@/openapi'
import { TEnergySavingTable } from '@/pages/reporting/energy-saving'
import theme from '@/theme'
import { filterByUniqueId } from '@/utils/arrayHelper'
import { convertEnergySavingToCsv } from '@/utils/csvHelper'
import { sumOfMatrix } from '@/utils/math'
import { thousandSeparator } from '@/utils/numHelper'
import useStore from '@/zustand/sotre'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, makeStyles } from '@material-ui/core'
import React, { useMemo, useState, SetStateAction, useEffect } from 'react'

interface EnergySavingTable {
    data: TEnergySavingTable
    setCsvData: React.Dispatch<SetStateAction<Blob | undefined>>
}
interface EnergySavingLevelWithUnit extends EnergySavingLevel {
    units?: Unit[]
}

const useStyle = makeStyles({
    container: {
        padding: 10,
        border: `1px solid ${theme.colors.border}`,
        position: 'relative',
        '& .vertical-line': {
            position: 'absolute',
            left: 0,
            top: 0,
            backgroundColor: '#fff',
            width: 160,
            height: 'calc(100% - 58px)',
        },
        '& .desc': {
            marginTop: 10,
            textAlign: 'right',
            fontWeight: 300,
        },
    },
    tableContainer: {},
    stickyCol: {
        position: 'sticky',
        left: 1,
    },
    cell: { minWidth: 100, fontSize: 14, padding: 9 },
    headerCell: {
        fontWeight: 600,
        color: '#fff',
        backgroundColor: '#6E6E6E',
        borderRight: '3px solid #fff',
        borderBottom: '3px solid #fff',
    },
    unitCell: { backgroundColor: '#AAAAAA' },
    bodyCell: { color: '#222222', fontWeight: 300 },
    bodySiteCell: {
        backgroundColor: '#FDF3DF',
        borderRight: '3px solid #fff',
        borderBottom: '3px solid #fff',
    },
    bodyTotalCell: { fontWeight: 700, backgroundColor: '#F5DBAE' },
})
type Unit = { name: string[]; id?: number }
const genUnits = (isCsvDownload?: boolean, isFullSize?: boolean, id?: number, unit: string = 'kl'): Unit[] => {
    const result = [
        { name: ['①活動量', `（${unit}）`], id },
        { name: ['②排出量', '（t-CO₂e）'], id },
        { name: ['③熱量', '（GJ）'], id },
    ]
    if (isFullSize) {
        result.splice(1, 0, { name: ['①活動量', '（kwh）'], id })
        result.splice(1, 0, { name: ['①活動量', '（1000Nm3）'], id })
        result.splice(1, 0, { name: ['①活動量', '（t）'], id })
    }
    if (isCsvDownload) {
        result.push({ name: ['④係数'], id })
    }
    return result
}

function EnergySavingTable({ data: { headers, filteredData: data }, ...props }: EnergySavingTable) {
    const classes = useStyle()
    const {
        storeState: { sites },
    } = useStore()
    const [tableCol, setTableCol] = useState<Unit[]>([])
    const [tableColCSV, setTableColCSV] = useState<Unit[]>([])

    function getLastLevelChildren(obj: EnergySavingLevel) {
        let lastLevelChildren: EnergySavingLevel[] = []
        if (obj.children) {
            obj.children.forEach((child) => {
                if (child.children) {
                    lastLevelChildren = lastLevelChildren.concat(child.children)
                } else {
                    lastLevelChildren.push(child)
                }
            })
        }
        return lastLevelChildren
    }

    const tableHeader = useMemo(() => {
        const colUnitsDownload: Unit[][] = []
        const newHeaders = headers.map((h) => {
            const children = getLastLevelChildren(h)
            const colUnits = children
                .map((child) => {
                    const result = genUnits(false, false, child.id, child.gj_unit)
                    const resultForDownload = genUnits(true, false, child.id, child.gj_unit)
                    colUnitsDownload.push(resultForDownload)
                    return result
                })
                .flat()
            setTableCol((pre) => pre.concat(colUnits))
            setTableColCSV(colUnitsDownload.flat())
            return { ...h, units: colUnits }
        })
        return [
            { name: '拠点名' },
            { name: '合計', units: genUnits(false, true) },
            ...newHeaders,
        ] as EnergySavingLevelWithUnit[]
    }, [headers])

    const generateTableData = (isCsvDownload: boolean = false) => {
        const result = data.map((site: EnergySavingReport) => {
            const values: number[][] = []
            const totalUnits: { [key: string]: number } = {
                kl: 0,
                kwh: 0,
                Nm3: 0,
                t: 0,
                tCO2: 0,
                gJ: 0,
            }
            const uniqueTableCol = filterByUniqueId(tableCol)
            uniqueTableCol.forEach((col, index) => {
                const valuesEg = [0, 0, 0]
                if (isCsvDownload) {
                    valuesEg.push(0)
                }
                values.push(valuesEg)
                site.data?.forEach((data) => {
                    if (data?.energy_saving_id === col.id) {
                        if (data?.activity) {
                            values[index][0] = data.activity
                            switch (data.unit) {
                                case 'kl': {
                                    totalUnits.kl = totalUnits.kl + data.activity
                                    break
                                }
                                case 't': {
                                    totalUnits.t = totalUnits.t + data.activity
                                    break
                                }
                                case '1000Nm3': {
                                    totalUnits.Nm3 = totalUnits.Nm3 + data.activity
                                    break
                                }

                                case 'kwh': {
                                    totalUnits.kwh = totalUnits.kwh + data.activity
                                    break
                                }
                            }
                        }
                        if (data?.emission_number) {
                            values[index][1] = data.emission_number
                            totalUnits.tCO2 = totalUnits.tCO2 + data.emission_number
                        }
                        if (data?.amount_heat) {
                            values[index][2] = data.amount_heat
                            totalUnits.gJ = totalUnits.gJ + data.amount_heat
                        }
                        if (isCsvDownload && data?.coefficient) {
                            values[index][3] = data.coefficient
                        }
                    }
                })
            })
            const data = [
                totalUnits.kl,
                totalUnits.t,
                totalUnits.Nm3,
                totalUnits.kwh,
                totalUnits.tCO2,
                totalUnits.gJ,
                ...values.flat(),
            ]
            return {
                name: site.site_name,
                data,
            }
        })
        return result
    }

    const tableData = useMemo(() => {
        const result = generateTableData()
        const matrixData = result.map((d) => d.data)
        const totalRows = sumOfMatrix(matrixData)
        const rowData = [...result, { name: '合計', data: totalRows }]
        return rowData
    }, [sites, data, tableCol])

    useEffect(() => {
        // FIXME: to be improved
        const { grandParent, parent, children } = require('../../../../dummy/energy_saving.json')
        const subHeader = genUnits(false, true)
        const csvResult = generateTableData(true)
        const matrixData = csvResult.map((r) => r.data)
        const units = subHeader.concat(tableColCSV).map((head) => {
            let unit = head.name.toString().replace(',', '')
            if (unit.includes('t-CO₂e')) {
                unit = unit.replace('t-CO₂e', 't-CO2e')
            }
            return unit
        })
        const data = csvResult.map((re) => [re.name, ...re.data])
        const total = sumOfMatrix(matrixData)
        const csvRowData = [grandParent, parent, children, ['-', ...units], ...data, ['合計', ...total]]
        const csvData = convertEnergySavingToCsv(csvRowData)
        props.setCsvData(csvData)
    }, [tableData])

    return (
        <div className={classes.container}>
            <div className="vertical-line" />
            <TableContainer className={classes.tableContainer}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {tableHeader.map((item, idx) => {
                                return (
                                    <TableCell
                                        key={idx}
                                        style={{ minWidth: 150 }}
                                        colSpan={item?.units?.length || 1}
                                        rowSpan={idx === 0 ? 4 : idx === 1 ? 3 : 1}
                                        className={`${classes.cell} ${classes.headerCell} ${
                                            !idx ? classes.stickyCol : ''
                                        }`}
                                        align="center"
                                    >
                                        {item.name}
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                        <TableRow>
                            {tableHeader.map((item) => {
                                if (!item?.children?.length) return null
                                return item?.children?.map((inner, idx) => {
                                    return (
                                        <TableCell
                                            key={idx}
                                            colSpan={inner?.children ? inner?.children?.length * 3 : 3}
                                            rowSpan={inner?.children?.length ? 1 : 2}
                                            className={`${classes.cell} ${classes.headerCell}`}
                                            align="center"
                                        >
                                            {inner.name}
                                        </TableCell>
                                    )
                                })
                            })}
                        </TableRow>
                        <TableRow>
                            {tableHeader.map((item) => {
                                return item?.children?.map((inner) => {
                                    return inner?.children?.map((inner2, idx) => {
                                        return (
                                            <TableCell
                                                key={idx}
                                                colSpan={inner2.children ? inner2.children?.length * 3 : 3}
                                                className={`${classes.cell} ${classes.headerCell}`}
                                                align="center"
                                            >
                                                {inner2.name}
                                            </TableCell>
                                        )
                                    })
                                })
                            })}
                        </TableRow>
                        <TableRow>
                            {tableHeader.map((h) => {
                                if (!h?.units) return
                                return h?.units?.map((unit, idx) => {
                                    return (
                                        <TableCell
                                            key={idx}
                                            className={`${classes.cell} ${classes.headerCell} ${classes.unitCell}`}
                                            align="center"
                                        >
                                            {unit.name.map((name, idx) => (
                                                <span
                                                    key={idx}
                                                    style={{ fontSize: idx ? 12 : 14, display: 'inline-block' }}
                                                >
                                                    {name}
                                                </span>
                                            ))}
                                        </TableCell>
                                    )
                                })
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableData.length > 1 ? (
                            tableData.map((site, idx) => {
                                return (
                                    <TableRow key={idx}>
                                        <TableCell
                                            className={`${classes.cell} ${classes.bodyCell} ${classes.bodySiteCell} ${
                                                idx === tableData.length - 1 ? classes.bodyTotalCell : ''
                                            } ${classes.stickyCol}`}
                                        >
                                            {site.name}
                                        </TableCell>
                                        {site.data.map((value, i) => {
                                            return (
                                                <TableCell
                                                    key={i}
                                                    className={`${classes.cell} ${classes.bodyCell}`}
                                                    align={value ? 'right' : 'center'}
                                                >
                                                    {value ? thousandSeparator(`${Math.round(value)}`) : '-'}
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                )
                            })
                        ) : (
                            <TableRow>
                                <TableCell colSpan={tableCol.length + 7} align="center" className={``}>
                                    <NoRowsOverlay />
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <div className="desc">※各項目の数値は端数処理をしているため、小計と合計が一致しない場合があります。</div>
        </div>
    )
}

export default EnergySavingTable
